<template>
    <span>{{ params.data.roles.name.split('_').join(' ') | capitalize }}</span>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data: () => ({

  }),
  computed: {

  }
}
</script>

<style lang="scss" scpoped>

</style>
