<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>{{ chipText(params.value) }}</span>
    </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data: () => ({
    status: [
      {text: "Active", value: false, color: 'success'},
      {text: "Inactive", value: true, color: 'warning'},
    ],
  }),
  computed: {
    chipColor () {
      return (value) => {
        return this.status.find(status => status.value == value).color
      }
    },

    chipText () {
      return (value) => {
        return this.status.find(status => status.value == value).text
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
